import moment, { Moment } from 'moment'

export default function getFormattedOrderTimeSlot(
  timestamp: number | Moment | null,
  slotSize = 10,
) {
  if (timestamp) {
    const timeFrom = moment(timestamp)
    const timeTo = timeFrom.clone().add(slotSize, 'minutes')

    return `${timeFrom.format('LT')} - ${timeTo.format('LT')}`
  }

  return null
}
